import type { SVGProps } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={2.632}
    data-name="Component 73 \u2013 1"
    {...props}
  >
    <path
      d="M3.684 0H-.002v2.632h10V0H3.682Z"
      data-name="Path 1063"
    />
  </svg>
)
export default SvgComponent
