// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import Icon from '~/components/icons/Icon'
import FormError from '~/components/typography/FormError'
import Label from '~/components/typography/Label'
import IconCheckmarkWhiteSvgURL from '~/static/svg/IconCheckmarkWhite.svg'
import IconMinus from '~/static/svg/IconMinus'

export interface Props
  extends InputProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  dangerouslySetLabel?: string
  error?: string
  inputClassName?: string
  labelClassName?: string
  label?: string
  labelChild?: JSX.Element
  required?: boolean
  half?: boolean
}

const InputCheckbox: React.FC<Props> = ({
  children,
  className,
  dangerouslySetLabel,
  defaultChecked,
  disabled,
  error,
  inputClassName,
  labelClassName,
  label,
  labelChild,
  required,
  inputType,
  half,
  ...other
}) => {
  const { name, value = '1' } = other
  const id_ = `id_${name}_${value}`
  const errorId = `id_${name}_error`

  const boxClassName = ' h-[20px] w-[20px]'

  return (
    <div
      className={'mb-3 w-full text-left' + (className ? ` ${className}` : '')}
    >
      <div className="relative flex items-start">
        <div
          className={
            'c-input-checkbox relative mr-2 inline-block flex-shrink-0' +
            boxClassName
          }
        >
          <input
            type="checkbox"
            aria-label={label}
            aria-required={required}
            aria-invalid={!!error}
            aria-errormessage={errorId}
            id={id_}
            defaultChecked={defaultChecked}
            disabled={disabled}
            name={name}
            value={value}
            className={
              ' peer absolute top-0 left-0 z-20 appearance-none bg-transparent opacity-100' +
              boxClassName +
              (disabled ? ' cursor-not-allowed' : ' cursor-pointer')
            }
            {...other}
          />
          <div
            className={
              'absolute top-0 left-0 border' +
              boxClassName +
              (error ? ' border-danger' : '') +
              (disabled
                ? ' border-gray-bg-2 bg-gray-bg-2'
                : ' border-brand-input-border peer-checked:bg-brand-input-border')
            }
          />
          {!half && (
            <Icon
              alt=""
              src={IconCheckmarkWhiteSvgURL}
              color="white"
              width="8px"
              className="c-checkmark absolute top-[7px] left-[6px] z-10 hidden"
            />
          )}
          {half && (
            <IconMinus
              fill="#ffffff"
              width="8px"
              className="c-checkmark absolute top-[9px] left-[6px] z-10 hidden"
            />
          )}
        </div>
        <div className="flex">
          {label && (
            <React.Fragment>
              <Label
                inputType={inputType}
                htmlFor={id_}
                className={'mb-0 block cursor-pointer'}
              >
                {label}
                {required ? ' *' : ''}
              </Label>
              {labelChild && labelChild}
            </React.Fragment>
          )}
          {dangerouslySetLabel && (
            <Label
              inputType={inputType}
              htmlFor={id_}
              className={'block '}
              dangerouslySet={dangerouslySetLabel}
            />
          )}
        </div>
      </div>
      {name && (
        <FormError
          inputType={inputType}
          error={error}
          className=""
          id={errorId}
        />
      )}
    </div>
  )
}

export default InputCheckbox
